import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorService } from '../shared/http-error.service';
import { VersioningService } from '../shared/versioning.service';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css'],
})
export class AuthComponent implements OnInit {
  @ViewChild('pincode') public pincode: ElementRef;
  public err: HttpErrorResponse;
  public waitingForLoginValidation = false;
  public factor2mail = false;
  public dsgvo = false;
  public kiText = false;
  public versions: any;
  protected showOverlay = false;

  constructor(
    private authService: AuthService,
    private route: Router,
    private aRoute: ActivatedRoute,
    private httpErrorService: HttpErrorService,
    private toastr: ToastrService,
    private versioningService: VersioningService
  ) {
    this.aRoute.queryParams.subscribe((params) => {
      if (params.sessionexpired) {
        this.toastr.error($localize`Sitzung abgelaufen. Bitte melden Sie sich erneut an.`);
        this.route.navigate([], {
          queryParams: {
            sessionexpired: null
          },
          queryParamsHandling: 'merge'
        });
      }
    })
  }

  async ngOnInit(): Promise<void> {
    if (this.authService.hasSession()) {
      this.authService.clearSession();
      window.location.reload()
    }

    this.httpErrorService.getError().subscribe((err) => {
      this.err = err;
    });
    this.versions = await this.versioningService.getVersions();
  }

  async onSubmit(form) {
    if (!this.kiText) {
      this.showOverlay = true;
      return
    } else {
      this.showOverlay = false;
    }
    let pin = "";
    for (const key in form.value) {
      pin += form.value[key];
    }
    pin = this.getFullPin();
    this.authService.setPin(pin);
    this.authService.setPrmWebAppLastUrl(null);

    this.authService.login().subscribe((value) => {
      if (value.status === 'accept') {
        this.authService.setWebAppToken(value.webapptoken);
        const authData = this.authService.getAuthData();

        const localStorageObject = this.authService.getPrmWebAppLastUrl();
        if (
          localStorageObject &&
          localStorageObject.url !== '/auth' &&
          localStorageObject.pin === authData.pin &&
          localStorageObject.iPadId === authData.iPadId
        ) {
          this.route.navigate([localStorageObject.url]);
          return;
        }

        if (
          window.location.hostname !== 'localhost' &&
          window.location.pathname.indexOf('/webapp/' + value.lang) < 0
        ) {
          // wrong language frontend loaded switching
          if (authData.role === 'moderator' || authData.role === 'observer') {
            window.location.href = '/webapp/' + authData.lang + '/#/observer/introduction/docs';
          } else if (authData.role === 'participant') {
            window.location.href = '/webapp/' + authData.lang + '/#/participant/introduction/docs';
          }
        } else if (authData.role === 'moderator' || authData.role === 'observer') {
          this.route.navigate(['/observer/introduction/docs']);
        } else if (authData.role === 'participant') {
          this.route.navigate(['/participant/introduction/docs']);
        }
      } else if (value.status === 'decline') {
        this.err = new HttpErrorResponse({ status: 401 });
      } else if (value.status === 'factor2mail') {
        this.waitingForLoginValidation = true
        this.factor2mail = true;
      } else {
        this.waitingForLoginValidation = false;
        this.factor2mail = false;
      }
    });
    this.waitingForLoginValidation = true;
  }

  public getFullPin() {
    let r = "";
    for (let i = 1; i <= 6; i++) {
      //@ts-ignore
      r += document.getElementById("nip" + i).value || " ";
    }
    return r;
  }

  public punschPin(v: number) {
    let value = this.pincode.nativeElement.value + '';
    if (v === -1) {
      value = value.slice(0, -1);
    } else if (value.length < 6) {
      value += v;
    }

    this.pincode.nativeElement.value = value;
  }

  public onPaste(event: ClipboardEvent) {
    event.preventDefault();

    //@ts-ignore
    let clipboardData = event.clipboardData || window.clipboardData;
    let pastedText = clipboardData.getData('text');
    pastedText = pastedText.trim();
    // SIEHT KOMISCH AUS IST ABER RICHIG
    if (isNaN(Number(pastedText))) {
      return;
    }
    let array = pastedText.split("");
    if (array.length != 6) {
      return;
    }
    array.forEach((element, i) => {
      //@ts-ignore
      document.getElementById("nip" + (i + 1)).value = element;
    });
    this.getFullPin();
  }

  public processKeyUp(e, lastEl, nextEl: HTMLElement) {
    if (e.target.value.split("").length > 1) {
      e.target.value = e.target.value.substr(e.target.value.split("").length - 1, 1)
    }

    if (e.key === 'Tab' || e.key === 'Shift') {
      return;
    }

    if (e.key === 'Backspace') {
      lastEl.focus();
    } else if (e.key >= '0' && e.key <= '9') {
      if (nextEl.id === 'login') {
        nextEl.click();
      } else {
        nextEl.focus();
      }
    }

    this.getFullPin();
  }
}